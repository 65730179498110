import "../css/product-details.scss";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Data from "../product.json";
import { useEffect, useState } from "react";
import truck from "../img/icon-delivery.png";
import refresh from "../img/Icon-return.png";
import { SearchHeader } from "../product-list";
import { useCart } from "./context";
import { BsFillCartCheckFill } from "react-icons/bs";

import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { Link } from "react-router-dom";

function ProductDetails() {
  const { cartData, setCartData } = useCart();
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const { Id } = useParams();
  const [item, setItem] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchItemValue = async () => {
      try {
        const foundItem = Data.find((value) => value.Id === Id);

        if (foundItem) {
          console.log(foundItem);
          setItem(foundItem);
          // Move setSelectedImage inside this block
          setSelectedImage(foundItem.ImgGroup[0]);
          console.log("Selected Image URL:", foundItem.ImgGroup[0]);
        } else {
          setError("Item not found");
        }
      } catch (error) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchItemValue();
  }, [Id]);

  // increment decrement

  const initialCount = parseInt(localStorage.getItem("count"), 10) || 0;
  const [countState, setCount] = useState(initialCount);

  useEffect(() => {
    const savedCount = localStorage.getItem("count");
    if (savedCount !== null) {
      setCount(parseInt(savedCount, 10));
    }
  }, []);

  const increment = () => {
    setCount(countState + 1);
  };

  const decrement = () => {
    if (countState > 0) {
      setCount(countState - 1);
    }
  };

  useEffect(() => {
    localStorage.setItem("count", countState.toString());
  }, [countState]);

  const handlePostalCodeChange = (e) => {
    const code = e.target.value;
    setPostalCode(code);

    if (!isValidPostalCode(code)) {
      setPostalCodeError("Invalid postal code");
    } else {
      setPostalCodeError("");
    }
  };

  const isValidPostalCode = (code) => {
    return /^\d{5}$/.test(code);
  };

  const handleThumbnailClick = (image) => {
    console.log("Thumbnail clicked:", image);
    setSelectedImage(image);
  };

  // add to cart functionality

  const addToCart = () => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    const updatedCart = [...existingCart];
    const existingItemIndex = updatedCart.findIndex(
      (cartItem) => cartItem.id === item.Id
    );

    if (existingItemIndex !== -1) {
      updatedCart[existingItemIndex].quantity = countState;
      updatedCart[existingItemIndex].selectedImage = selectedImage;
    } else {
      updatedCart.push({
        id: item.Id,
        productName: item.ProductName,
        price: parseFloat(item.Price.replace("£", "")),
        quantity: countState,
        selectedImage: selectedImage,
      });
    }

    localStorage.setItem("cart", JSON.stringify(updatedCart));

    setCartData(updatedCart);
  };

  return (
    <section>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {item && (
        <>
          <section className="product-list">
            <Container>
              <Row className="align-items-center">
                <SearchHeader
                  searchText={searchText}
                  setSearchText={setSearchText}
                  cartData={cartData}
                />
              </Row>
            </Container>
          </section>
          <div className="detail-pages">
            <Container>
              <Row className="align-items-center">
                <Col xxl={2} xl={2} lg={2} md={3} sm={3}>
                  {item.ImgGroup.map((value, index) => (
                    <div className="view-points" key={index}>
                      <img
                        src={value}
                        alt={"product"}
                        onClick={() => handleThumbnailClick(value)}
                      />
                    </div>
                  ))}
                </Col>

                <Col xxl={5} xl={6} lg={6} md={9} sm={9}>
                  <div className="main-display">
                    <img src={selectedImage} alt="main-view" />
                  </div>
                </Col>

                <div className="mobile-view-view">
                  {item.ImgGroup.map((value, index) => (
                    <div className="view-mobile" key={index}>
                      <img
                        src={value}
                        alt={"product"}
                        onClick={() => handleThumbnailClick(value)}
                      />
                    </div>
                  ))}
                </div>

                <Col xxl={5} xl={4} lg={4} md={12}>
                  <div className="details">
                    <h1 className="title">{item.ProductName}</h1>
                    <div className="rev-stk">
                      <div className="stars">
                        <>
                          {[...Array(item.Rating)].map((rating) => (
                            <div key={rating}>
                              <AiFillStar />
                            </div>
                          ))}
                          {[...Array(5 - item.Rating)].map((rating) => (
                            <div key={rating}>
                              <AiOutlineStar />
                            </div>
                          ))}
                        </>
                      </div>
                      |
                      <div className="stock">
                        <p
                          className={`title ${
                            item.Stock ? "" : "out-of-stock"
                          }`}
                        >
                          {item.Stock ? "In Stock" : "Out of Stock"}
                        </p>
                      </div>
                    </div>
                    <div className="price">{item.Price}</div>
                    <div className="desc">{item.Description}</div>
                    <div className="line"></div>
                    <div className="add-flex-icon">
                      <div className="counter">
                        <Button className="minus" onClick={decrement}>
                          -
                        </Button>
                        <h6 className="number">{countState}</h6>
                        <Button className="plus" onClick={increment}>
                          +
                        </Button>
                      </div>
                      <div className="cart">
                        <Button className="add" onClick={addToCart}>
                          Add Cart
                        </Button>
                      </div>

                      <div className="like">
                        {item.Favourite ? <AiFillHeart /> : <AiOutlineHeart />}
                      </div>
                    </div>
                    <div className="delivery-return">
                      <div className="delivery">
                        <img src={truck} alt="truck-img" />
                        <div className="cnt">
                          <div className="title">Free Delivery</div>
                          <input
                            type="text"
                            id="myInputField"
                            placeholder="Enter your postal code for Delivery Availability"
                            value={postalCode}
                            onChange={handlePostalCodeChange}
                          />

                          {postalCodeError.length === 0 && (
                            <div className="error-message">
                              {postalCodeError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="return">
                        <img src={refresh} alt="loding-img" />
                        <div className="cnt">
                          <div className="title">Return Delivery</div>
                          <div className="desc">
                            Free 30 Days Delivery Returns.
                            <span>
                              <Link to={"/"}>Details</Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-button-style">
                    <Link to="/cart">
                      <button className="footer-cart-detail-btn">
                        <BsFillCartCheckFill />
                        <div>
                          <span>1 Items</span>
                          <span>£192.00</span>
                        </div>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </section>
  );
}

export default ProductDetails;
