import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./detailsupdate.scss";
// import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { CgCloseR } from "react-icons/cg";
import { Link } from "react-router-dom";
// import Ordersuccess from "../ordersuccess/ordersuccess";
import Addsuccess from "../addsuccess/addsuccess";

const Detailsupdate = ({ showDetailsUpdate }) => {
  // State for Details Update modal
  const [showAddSuccess, setShowAddSuccess] = useState(false);

  // const [setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [gender, setGender] = useState("male");

  const handleClose = () => {
    // setShow(false);
    setValidated(false);
    setEmailValid(true);
    // setShowDetailsUpdate(false);
    setShowAddSuccess(false);
  };
  // const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);

  // const handleShowDetailsUpdate = () => {
  //   setShowDetailsUpdate(true);
  //   setShowOrderSuccess(false);
  // };

  const handleShowAddSuccess = () => {
    // setShowDetailsUpdate(false);
    setShowAddSuccess(true);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // if (form.checkValidity() === false || !emailValid) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    if (form.checkValidity() === false || !emailValid || !phoneValid) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleShowAddSuccess();
    setValidated(true);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const handlePhoneChange = (event) => {
    const newPhone = event.target.value;
    const numericPhone = newPhone.replace(/[^0-9]/g, "");
    setPhone(numericPhone);
    validatePhone(numericPhone);
  };

  const validateEmail = (inputEmail) => {
    // Simple email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setEmailValid(isValid);
  };

  const validatePhone = (inputPhone) => {
    // Simple phone number validation using a regular expression
    const phoneRegex = /^\d{10}$/;
    const isValid = phoneRegex.test(inputPhone);
    setPhoneValid(isValid);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  return (
    <div>
      <section className="detailsupdate">
        {/* <Button variant="primary" onClick={handleShowDetailsUpdate}>
          Launch demo modal
        </Button> */}

        <Modal
          show={showDetailsUpdate}
          onHide={handleClose}
          animation={false}
          centered
          className="detailsupdate"
        >
          {/* <Modal.Header closeButton> */}
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          {/* </Modal.Header> */}
          <Modal.Body className="updatebody">
            <Button
              variant="primary"
              onClick={handleClose}
              className="closebtn"
            >
              <CgCloseR />
            </Button>
            <br></br>
            <h4>Review Delivery Details</h4>
            <br></br>
            <Container>
              <Form
                onSubmit={handleSubmit}
                validated={validated}
                className="detailsform"
              >
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom01"
                  >
                    <label className="namelabel">First Name:</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter your name.."
                      className="detailname"
                    />
                    <div className="invalid-feedback">
                      Enter a valid First name.
                    </div>
                    {/* <span style={{ color: "red" }}>{formErrors.firstName}</span> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="namelabel">Last Name:</label>

                    <input
                      className="detailname"
                      required
                      type="text"
                      placeholder="Enter your name.."
                    />

                    <div className="invalid-feedback">
                      Enter a valid Last Name.
                    </div>
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                </Row>
                <br></br>
                <Row>
                  <Col xs={6} md={6}>
                    <label htmlFor="email" className="namelabel">
                      Email Id:
                    </label>

                    <input
                      className={`detailname ${
                        validated && !emailValid ? "is-invalid" : ""
                      }`}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="info@xyz.com"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />

                    <div className="invalid-feedback">
                      Enter valid Email Id.
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <label htmlFor="phone" className="namelabel">
                      Mobile No:
                    </label>

                    <input
                      className={`detailname ${
                        validated && !phoneValid ? "is-invalid" : ""
                      }`}
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="+91 - 98596 58000"
                      pattern="[0-9]{10}"
                      value={phone}
                      onChange={handlePhoneChange}
                      required
                    />

                    <div className="invalid-feedback">Must be 10 digits.</div>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom01"
                  >
                    <label className="namelabel">Door No:</label>

                    <input
                      required
                      type="text"
                      placeholder="Enter Post Code ..."
                      className="detailname"
                    />

                    <div className="invalid-feedback">Enter Door No.</div>
                    {/* <span style={{ color: "red" }}>{formErrors.firstName}</span> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="namelabel">Street:</label>

                    <input
                      className="detailname"
                      required
                      type="text"
                      placeholder="Enter Street name ..."
                    />
                    <div className="invalid-feedback">Enter Street Name.</div>
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                </Row>
                <br></br>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="namelabel">City:</label>

                    <input
                      className="detailname"
                      required
                      type="text"
                      placeholder="Enter city name ..."
                    />

                    <div className="invalid-feedback">Enter city name ...</div>
                  </Form.Group>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="radioaddress">
                      <Form.Check
                        className="radiolabel"
                        type="radio"
                        label="Current Address"
                        name="gender"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleGenderChange}
                      />
                      <Form.Check
                        className="radiolabel"
                        type="radio"
                        label="Add Address"
                        name="gender"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleGenderChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="submitbtn">
                      <Link to={"/addsuccess"}>
                        <Button variant="primary" onClick={handleSubmit}>
                          Update Details
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
        {showAddSuccess && <Addsuccess onClose={handleClose} />}
      </section>
    </div>
  );
};

export default Detailsupdate;
