import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./shopsigninpopup.scss";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { CgProfile } from "react-icons/cg";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { TfiEmail } from "react-icons/tfi";
import { MdLockOutline } from "react-icons/md";
import { CgCloseR } from "react-icons/cg";
// import { Link } from "react-router-dom";
import Detailsupdate from "../detailsupdate/detailsupdate";

function Shopsigninpopup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateForm = () => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    // Basic password validation (at least 6 characters)
    if (password.length < 6) {
      alert("Password must be at least 6 characters.");
      return false;
    }

    // If all validations pass, you can submit the form
    alert("Form submitted successfully!");
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
  };

  const [show, setShow] = useState(false);
  const [showDetailsUpdate, setShowDetailsUpdate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleset = () => {
    setShowDetailsUpdate(true);
  };
  return (
    <div className="shopsigninpopup">
      <section>
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="shopsigninpopup"
        >
          <Modal.Body className="signinbody">
            <Button
              variant="primary"
              onClick={handleClose}
              className="closebtn"
            >
              <CgCloseR />
            </Button>
            <br></br>
            <Container>
              <Row>
                <Col xs={6} md={6}>
                  <p>Get's Started</p>

                  <span>
                    New User? <button>Sign Up</button>
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <div className="guestprofile">
                    <button>
                      <CgProfile className="profile" />
                      Continue as Guest
                    </button>
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col xs={6} md={6}>
                  <div className="guest">
                    <button>
                      <BiLogoFacebookCircle className="facebook" />
                      Sign in with Facebook
                    </button>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div className="guest">
                    <button>
                      <FcGoogle className="google" />
                      Sign in with Google
                    </button>
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col xs={12} md={12}>
                  <div className="middleor">
                    <p>Or</p>
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <form onSubmit={handleSubmit}>
                  <Col xs={12} md={12}>
                    <div className="mail">
                      <label htmlFor="email" className="maillabel">
                        Email address
                      </label>
                      <input
                        className="mailtext"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Email Id"
                        required
                      />
                      <TfiEmail className="mailsvg" />
                    </div>
                  </Col>
                  <br></br>
                  <Col xs={12} md={12}>
                    <div className="mail">
                      <label htmlFor="password" className="maillabel">
                        Password
                      </label>

                      <input
                        className="passwordtext"
                        type="password"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <MdLockOutline className="passwordsvg" />
                    </div>
                  </Col>
                  <div className="forget">
                    <button type="button">Forget Password?</button>
                  </div>
                  <div className="submit">
                    <button onClick={handleset}>submit</button>
                    <Detailsupdate
                      showDetailsUpdate={showDetailsUpdate}
                    ></Detailsupdate>
                  </div>
                </form>
              </Row>
            </Container>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </section>
    </div>
  );
}

export default Shopsigninpopup;
