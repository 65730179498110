import React from "react";
import "./ordertracking.scss";
import mapimg from "./img/Component 6.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import photo from "./img/Mask group.png";
import { RiGlobalLine } from "react-icons/ri";
import { IoCallOutline } from "react-icons/io5";
import StatusProgressbar from "../../statusprogressbar/statusbar";
import { Link } from "react-router-dom";

function Ordertracking() {
  return (
    <>
      <section className="ordertracking">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="map">
                <img src={mapimg} alt="" title="" className="mapimg" />
                <Link to="/status">
                  <div className="backbtn">
                    {/* <button>qwer</button> */}
                    <IoMdArrowRoundBack className="button" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="details-cont">
                <div className="img">
                  <img src={photo} alt="" title="" className="photo" />
                  <div className="qwe">
                    <div className="name">
                      <h5>Alexander Jr</h5>
                      <p>couries</p>
                    </div>
                    {/* <br></br> */}
                    <div className="call-map">
                      <div className="map">
                        <RiGlobalLine className="mapimg" />
                      </div>
                      <div className="call">
                        <IoCallOutline className="callimg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="progressbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4>Progress of your Order</h4>
              <br></br>
              <StatusProgressbar />
              <br></br>
              <div className="donebtn">
                <Link to="/">
                  <button>Mark as Done</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ordertracking;
