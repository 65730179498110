// CartContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState([]);

  const clearCart = () => {
    localStorage.removeItem("cart");
    setCartData([]);
  };
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    if (Array.isArray(storedCart) && storedCart.length > 0) {
      setCartData(storedCart);
    }
  }, [setCartData]);

  return (
    <CartContext.Provider value={{ cartData, setCartData, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
