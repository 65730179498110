import React from "react";
import { useEffect } from "react";
import "./addsuccess.scss";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import addedsuccess from "./img/unnamed.gif";

function Addsuccess({ setShowAddsuccess, showAddsuccess }) {
  // const [show, setShow] = useState(false);

  const handleClose = () => {
    setShowAddsuccess(false);
  };
  // const handleShow = () => setShow(true);

  useEffect(() => {
    let timeout;

    // Set a timeout to close the modal after 5 seconds when it is opened
    if (showAddsuccess) {
      timeout = setTimeout(() => {
        setShowAddsuccess(false);
      }, 5000);
    }

    // Clear the timeout if the modal is closed before the timeout completes
    return () => clearTimeout(timeout);
  }, [showAddsuccess, setShowAddsuccess]);

  return (
    <section>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        show={showAddsuccess}
        onHide={handleClose}
        animation={false}
        centered
        className="addedsuccess"
      >
        <Modal.Header className="addsuccesshead">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="addsuccessbody">
          <img src={addedsuccess} alt="" title="" className="addedsuccessimg" />
          <p>Thank you</p>
          <h4>Added Successfully</h4>
        </Modal.Body>
        <Modal.Footer className="addsuccessfoot">
          {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Addsuccess;
