// *******~ Import ~******** //

//? React
import React, { useState, useEffect, useContext, createContext } from "react";
//? Assets
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { TiArrowBackOutline } from "react-icons/ti";
//? Components
import Products from "./product.json";
import Cart from "./product-details/cartData";

//? CSS
import "./css/sidebar.scss";
import "./css/product-list.scss";
import "./css/canva.scss";

//? Images

//? JSON File

//? Icons
import { BsFillCartCheckFill } from "react-icons/bs";
import { TbFilterCancel } from "react-icons/tb";
import { RiListSettingsFill } from "react-icons/ri";
import { IoIosArrowDropright } from "react-icons/io";
import {
  AiOutlineSearch,
  AiFillStar,
  AiOutlineStar,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { useCart } from "./product-details/context";
// *******~ Import ~******** //
export const ContextFilterProvider = createContext({});
export const ContextFilterConsumer = () => useContext(ContextFilterProvider);
const ProductList = () => {
  const { cartData, setCartData } = useCart();
  const storedCategory = (
    localStorage.getItem("ProductCategory") || "All Categories"
  ).toLowerCase();
  const [selectedCategory, setSelectedCategory] = useState(storedCategory);
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [searchText, setSearchText] = useState("");
  const [Stock, setStock] = useState("");

  const filteredProducts = Products.filter((product) => {
    const productName = product.ProductName.toLowerCase();
    const selectedCategoryLower = selectedCategory
      ? selectedCategory.toLowerCase()
      : "";
    const selectedProductTypeLower = selectedProductType
      ? selectedProductType.toLowerCase()
      : "";
    const selectedBrandLower = selectedBrand ? selectedBrand.toLowerCase() : "";
    const searchTextLower = searchText.toLowerCase();

    const excludedKeywords = [
      "terminal",
      "printer",
      "card machine",
      "cash drawer",
      "stand",
      "android tab",
      "caller id",
      "kitchen display",
    ];

    const excludeData =
      selectedCategoryLower === "other" &&
      !excludedKeywords.some((keyword) =>
        productName.toLowerCase().includes(keyword)
      );

    const categoryMatch =
      selectedCategory &&
      (selectedCategory.toLowerCase() === "all categories" ||
        (selectedCategory.toLowerCase() === "other" && excludeData) ||
        productName.includes(selectedCategory.toLowerCase()));

    const productTypeMatch =
      selectedProductTypeLower === "" ||
      productName.includes(selectedProductTypeLower);

    const brandMatch =
      selectedBrandLower === "" || productName.includes(selectedBrandLower);

    const searchMatch = productName.includes(searchTextLower);

    return categoryMatch && productTypeMatch && brandMatch && searchMatch;
  });
  // Responsive Js
  const [width, setWidth] = useState(window.innerWidth);
  // const breakpoint = 767;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const clearCart = () => {
    // Assuming setCartData is available in your context
    setCartData([]);
    // Clear cart data from local storage
    localStorage.removeItem("cart");
  };

  // Responsive Js End
  return (
    <ContextFilterProvider.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        selectedProductType,
        setSelectedProductType,
        selectedBrand,
        setSelectedBrand,
        filteredProducts,
        Stock,
        setStock,
        setSearchText,
        width,
        cartData,
        clearCartData: clearCart,
      }}
    >
      <section className="product-list">
        <Container>
          <Row>
            <SearchHeader
              searchText={searchText}
              setSearchText={setSearchText}
            />
            {width > 767 ? <Sidebar /> : null}
            <FilterProductList />
          </Row>
        </Container>
        {width < 576 ? (
          <>
            <Helmet>
              <style type="text/css">{`
       @media only screen and (min-width: 320px) and (max-width: 575px) {
        .copyright-sec {
          display:none;
        }
        .top-to-btm .icon-position {
          bottom: 80px;
          display:none;
        }
        .product-list .filter-product-list {
          margin-bottom:70px;
        }
        .theme-btn{
          display:none;
        }
        .whatsapp-chat{ display:none;}
        footer{ display:none;}
       }
    `}</style>
            </Helmet>
            <Link to="/cart">
              <button className="footer-cart-btn">
                <BsFillCartCheckFill />
                <p>
                  <span>1 Items</span>
                  <span>£192.00</span>
                </p>
              </button>
            </Link>
          </>
        ) : null}
      </section>
    </ContextFilterProvider.Provider>
  );
};
export default ProductList;

function RadioAnimation() {
  return (
    <div className="cbx">
      <div className="flip">
        <div className="front"></div>
        <div className="back">
          <svg width="16" height="14" viewBox="0 0 16 14">
            <path d="M2 8.5L6 12.5L14 1.5"></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

const Sidebar = () => {
  const {
    selectedCategory,
    setSelectedCategory,
    selectedProductType,
    setSelectedProductType,
    selectedBrand,
    setSelectedBrand,
    Stock,
    setStock,
  } = ContextFilterConsumer();
  const stockTrueCount = Products.filter((product) => product.Stock).length;
  const stockFalseCount = Products.filter((product) => !product.Stock).length;

  function getProductCount(products, productName) {
    return products.filter(
      (product) =>
        product.ProductName.toLowerCase() === productName.toLowerCase()
    ).length;
  }

  const AllCategoriesCount = Products.length;
  const PrinterCount = getProductCount(Products, "printer");
  const TerminalCount = getProductCount(Products, "terminal");
  const CardMachineCount = getProductCount(Products, "card machine");
  const CashDrawerCount = getProductCount(Products, "Cash Drawer");
  const StandCount = getProductCount(Products, "Stand");
  const CallerIdCount = getProductCount(Products, "Caller Id");
  const AndroidTabCount = getProductCount(Products, "Android Tab");
  const KitchenDisplayCount = getProductCount(Products, "Kitchen Display");
  const OtherCountExcludingAll =
    AllCategoriesCount -
    (TerminalCount +
      CardMachineCount +
      PrinterCount +
      CashDrawerCount +
      StandCount +
      AndroidTabCount +
      CallerIdCount +
      KitchenDisplayCount);
  const Categories = [
    { label: "All Categories", count: AllCategoriesCount },
    { label: "Terminal", count: TerminalCount },
    { label: "Card Machine", count: CardMachineCount },
    { label: "Printer", count: PrinterCount },
    { label: "Cash Drawer", count: CashDrawerCount },
    { label: "Stand", count: StandCount },
    { label: "Android Tab", count: AndroidTabCount },
    { label: "Caller Id", count: CallerIdCount },
    { label: "Kitchen Display", count: KitchenDisplayCount },
    { label: "Other", count: OtherCountExcludingAll },
  ];
  const Avaliability = [
    { label: "In Stock", count: stockTrueCount },
    { label: "Out of stock", count: stockFalseCount },
  ];
  const ProductType = ["Android Tab", "Apple Tab", "Windows"];
  const Brand = ["Brand A", "Brand B"];
  // Calculate the counts

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    localStorage.setItem("ProductCategory", event.target.value);
  };
  const handleProductType = (event) => {
    setSelectedProductType(event.target.value);
  };
  const handleBrand = (event) => {
    setSelectedBrand(event.target.value);
  };
  const handleStock = (event) => {
    setStock(event.target.value);
  };
  const StockReset = (event) => {
    setStock("");
  };
  const CategoryReset = (event) => {
    setSelectedCategory("All Categories");
    localStorage.setItem("ProductCategory", "All Categories");
  };
  const ProductTypeReset = (event) => {
    setSelectedProductType("");
  };
  const BrandReset = (event) => {
    setSelectedBrand("");
  };

  return (
    <Col xxl={3} xl={3} md={4}>
      <div className="sidebar">
        <div className="filter-list">
          <div className="heading">
            <h3>Categories</h3>
            <span onClick={CategoryReset}>Reset</span>
          </div>
          <div className="list">
            {Categories.map((category, index) => (
              <label key={category.label} className="list-item">
                <input
                  type="radio"
                  id={category.label}
                  value={category.label}
                  checked={
                    selectedCategory.toLowerCase() ===
                    category.label.toLowerCase()
                  }
                  onChange={handleCategoryChange}
                />
                <RadioAnimation />
                {category.label}
                <span>{category.count}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="filter-list">
          <div className="heading">
            <h3>Avaliability</h3>
            <span onClick={StockReset}>Reset</span>
          </div>
          <div className="list">
            {Avaliability.map((avaliability, index) => (
              <label key={index} className="list-item">
                <input
                  type="radio"
                  id={avaliability.label}
                  value={avaliability.label}
                  checked={Stock === avaliability.label}
                  onChange={handleStock}
                />
                <RadioAnimation />
                {avaliability.label}
                <span>{avaliability.count}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="filter-list">
          <div className="heading">
            <h3>Product Type</h3>
            <span onClick={ProductTypeReset}>Reset</span>
          </div>
          <div className="list">
            {ProductType.map((productyype) => (
              <label key={productyype} className="list-item">
                <input
                  type="radio"
                  id={productyype}
                  value={productyype}
                  checked={selectedProductType === productyype}
                  onChange={handleProductType}
                />
                <RadioAnimation />
                {productyype}
                <span>20</span>
              </label>
            ))}
          </div>
        </div>
        <div className="filter-list">
          <div className="heading">
            <h3>Brand</h3>
            <span onClick={BrandReset}>Reset</span>
          </div>
          <div className="list">
            {Brand.map((brand) => (
              <label key={brand} className="list-item">
                <input
                  type="radio"
                  id={brand}
                  value={brand}
                  checked={selectedBrand === brand}
                  onChange={handleBrand}
                />
                <RadioAnimation />
                {brand}
                <span>20</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </Col>
  );
};

const FilterProductList = () => {
  const {
    filteredProducts,
    Stock,
    setSelectedCategory,
    setSelectedProductType,
    setSelectedBrand,
    setStock,
    setSearchText,
    width,
  } = ContextFilterConsumer();
  const ClearAll = () => {
    setSelectedCategory("All Categories");
    setSelectedProductType("");
    setSelectedBrand("");
    setStock("");
    setSearchText("");
    localStorage.setItem("ProductCategory", "All Categories");
  };
  const stockFilter = Stock.toLowerCase();
  const inStockText = "in stock";
  const outOfStockText = "out of stock";

  const displayedProducts = filteredProducts.filter((product) => {
    if (stockFilter === inStockText) {
      return product.Stock;
    } else if (stockFilter === outOfStockText) {
      return !product.Stock;
    }
    return true;
  });
  return (
    <Col xxl={9} xl={9} md={8}>
      <div className="filter-product-list">
        <Row className="product-list-row">
          <>
            {displayedProducts &&
              displayedProducts.map((productlist, index) => (
                <>
                  <Col
                    xxl={4}
                    md={6}
                    sm={6}
                    xs={width > 410 ? 6 : 12}
                    lg={4}
                    xl={4}
                    key={index}
                  >
                    <Link to={`/product/${productlist.Id}`}>
                      <div className="product-box">
                        <Image
                          src={productlist.Image}
                          alt={`FusionPOS ${productlist.ProductName}`}
                          title={`FusionPOS ${productlist.ProductName}`}
                          fluid
                        />
                        <h5>{productlist.ProductName}</h5>
                        <p>{productlist.Price}</p>
                        <ul>
                          {[...Array(productlist.Rating)].map((rating) => (
                            <li key={rating}>
                              <AiFillStar />
                            </li>
                          ))}
                          {[...Array(5 - productlist.Rating)].map((rating) => (
                            <li key={rating}>
                              <AiOutlineStar />
                            </li>
                          ))}
                        </ul>
                        <span className="fav-icon">
                          {productlist.Favourite ? (
                            <AiFillHeart />
                          ) : (
                            <AiOutlineHeart />
                          )}
                        </span>
                      </div>
                    </Link>
                  </Col>
                </>
              ))}
            {displayedProducts.length === 0 && (
              <Col xxl={12} md={12} xl={12}>
                <div className="not-found-content">
                  <p>Nothing correlates to all those filters.</p>
                  <span>Try removing one or two filters</span>
                  <button onClick={ClearAll}>
                    <TbFilterCancel /> Clear all Filters
                  </button>
                </div>
              </Col>
            )}
          </>
        </Row>
      </div>
    </Col>
  );
};

function MobileSidebar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow}>
        <RiListSettingsFill />
      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        id="sidebar-filter-canva"
      >
        <Offcanvas.Body>
          <Button className="close-canva-btn" onClick={handleClose}>
            <IoIosArrowDropright />
          </Button>
          <Sidebar />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const SearchHeader = ({ setSearchText, searchText }) => {
  // Responsive Js
  const [width, setWidth] = useState(window.innerWidth);
  // const breakpoint = 767;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  // Responsive Js End
  const { cartData } = useCart();
  return (
    <>
      <Col xxl={12}>
        {width < 768 ? (
          <div className="go-back-btn">
            <Link to={"/"}>
              <TiArrowBackOutline /> Back to Shopping
            </Link>
          </div>
        ) : null}
        <div className="text-search">
          <form>
            <AiOutlineSearch />
            <input
              type="text"
              placeholder="What are you looking for?"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </form>
          {width > 575 ? (
            <div className="button-style">
              {" "}
              <Link to="/cart">
                <button>
                  <Cart cartData={cartData} />
                </button>
              </Link>
            </div>
          ) : null}
          {width < 768 ? <MobileSidebar /> : null}
        </div>
        {width < 768 ? (
          <div className="mob-titl">
            <h3>Categories</h3>
          </div>
        ) : null}
      </Col>
    </>
  );
};
