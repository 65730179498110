// *******~ Import ~******** //
// React
// Assets
// Components
// CSS
import "./whatsapp-chat.scss";
// Images
import WhatsAppIcon from "./new-wp.png";
// Icons
// *******~ Import ~******** //
export default function WhatsAppChat(second) {
  return (
    <>
      <div className="whatsapp-chat">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=+447860088200&text=I%27m%20interested%20in%20your%20product%20"
        >
          <img src={WhatsAppIcon} alt="" />
          <span></span>
        </a>
      </div>
    </>
  );
}
