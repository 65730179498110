import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./statusbar.scss";
import { Container, Row, Col } from "react-bootstrap";

class StatusProgressbar extends React.Component {
  state = {
    currentStep: 1,
  };

  handleStepChange = (nextStep) => {
    this.setState({ currentStep: nextStep });
  };

  render() {
    const { currentStep } = this.state;
    return (
      <section>
        <div className="status-progress-bar">
          <Container>
            <Row className="justify-content-center">
              <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                <ProgressBar
                  percent={(currentStep - 1) * 33.88}
                  className="status-details"
                >
                  <Step
                    transition="scale"
                    id={1}
                    accomplished={currentStep >= 1}
                  >
                    {({ accomplished }) => (
                      <div className="deliver">
                        <h6 className={accomplished ? "active " : "confirm"}>
                          Order Confirmed
                        </h6>
                        <div
                          className={accomplished ? "active green" : "gray"}
                        ></div>
                        <h6 className="days">Wed, 1 lth Jan</h6>
                      </div>
                    )}
                  </Step>
                  <Step
                    transition="scale"
                    id={2}
                    accomplished={currentStep >= 2}
                  >
                    {({ accomplished }) => (
                      <div className="deliver">
                        <h6 className={accomplished ? "active " : "confirm"}>
                          Shipped
                        </h6>
                        <div
                          className={accomplished ? "active green" : "gray"}
                        ></div>
                        <h6 className="days">Wed, 1 lth Jan</h6>
                      </div>
                    )}
                  </Step>
                  <Step
                    transition="scale"
                    id={3}
                    accomplished={currentStep >= 3}
                  >
                    {({ accomplished }) => (
                      <div className="deliver">
                        <h6 className={accomplished ? "active " : "confirm"}>
                          Out For Delivery
                        </h6>
                        <div
                          className={accomplished ? "active green" : "gray"}
                        ></div>
                        <h6 className="days">Wed, 1 lth Jan</h6>
                      </div>
                    )}
                  </Step>
                  <Step
                    transition="scale"
                    id={4}
                    accomplished={currentStep >= 4}
                  >
                    {({ accomplished }) => (
                      <div className="deliver">
                        <h6 className={accomplished ? "active " : "confirm"}>
                          Delivered
                        </h6>
                        <div
                          className={accomplished ? "active green" : "gray"}
                        ></div>
                        <h6 className="days">Expected by, Mon 16th</h6>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <section>
          <div>
            <div>
              <button onClick={() => this.handleStepChange(1)}>
                Complete Step 1
              </button>
              <button onClick={() => this.handleStepChange(2)}>
                Complete Step 2
              </button>
              <button onClick={() => this.handleStepChange(3)}>
                Complete Step 3
              </button>
              <button onClick={() => this.handleStepChange(4)}>
                Complete Step 4
              </button>
            </div>
          </div>
        </section> */}
      </section>
    );
  }
}

export default StatusProgressbar;
