// *******~ Import ~******** //
// React
import React, { useState, useContext } from "react";
// Assets
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

// Components
import ThemeContext from "../theme/components/contexts/themecontexts";
import MenuBtn from "./menubtn/menubtn";
import callicon from "./img/call.svg";
// import ThemeBtn from "../theme/components/themeshetter";
// CSS
import "./css/header.scss";

// Images
import LogoLight from "./img/fusionpos-logo.svg";
import ChefIcon from "./img/FusionPOS-CHEF-SCREEN.svg";
import FKIcon from "./img/FusionPOS-FUSION-KITCHEN.svg";
import MobIcon from "./img/FusionPOS-MOBILE-APPS.svg";
import Multiprint from "./img/FusionPOS-MULTI-PRINTER.svg";
import OrderReport from "./img/FusionPOS-ORDER-REPORT.svg";
import PreOrder from "./img/FusionPOS-PRE-ORDER.svg";
import FPicon from "./img/FusionPOS-FUSION-PARTNER-APP.svg";
import HowWork from "./img/FusionPOS-HOW-IT-WORKS.svg";
import ContactDine from "./img/FusionPOS-CONTACTLESS-DINING.svg";
import DeliMang from "./img/FusionPOS-DELIVERY-MANAGEMENT-SOFTWARE.svg";
import FreeOrder from "./img/FusionPOS-FREE-ORDERING-SYSTEM.svg";
// Responsive Img

// Icons

// *******~ Import ~******** //

const Header = (params) => {
  const { theme } = useContext(ThemeContext);
  const DropMenuLinks = [
    {
      title: "Features",
      submenus: [
        {
          img: ChefIcon,
          name: "CHEF SCREEN",
          dis: "Access to your Chefs to prepare orders on time",
          link: "/",
        },
        {
          img: MobIcon,
          name: "MOBILE APPS",
          dis: "Your own branded App, which is handy",
          link: "/",
        },
        {
          img: Multiprint,
          name: "MULTI PRINTER",
          dis: "Printers that make your business easy",
          link: "/",
        },
        {
          img: PreOrder,
          name: "PRE-ORDER",
          dis: "Receive orders from your customers in prior",
          link: "/",
        },
        {
          img: OrderReport,
          name: "ORDER REPORT",
          dis: "Report generated for your orders received",
          link: "/",
        },
      ],
    },
    {
      title: "Services",
      submenus: [
        {
          img: FreeOrder,
          name: "FREE ORDERING SYSTEM",
          dis: "Just use your Smart phone to receive orders online",
          link: "/",
        },
        {
          img: DeliMang,
          name: "DELIVERY MANAGEMENT SOFTWARE",
          dis: "Track their drivers who deliver the orders",
          link: "/",
        },
        {
          img: FKIcon,
          name: "FUSION KITCHEN",
          dis: "Brings you to your Customers with 0% Commission",
          link: "/",
        },
        {
          img: ContactDine,
          name: "CONTACTLESS DINING",
          dis: "To offer a Contactless Menu, Orders & Payment",
          link: "/",
        },
        {
          img: FPicon,
          name: "FUSION PARTNER APP",
          dis: "Access and manage your EPOS from anywhere now!",
          link: "/",
        },
        {
          img: HowWork,
          name: "HOW IT WORKS",
          dis: "Take a look to know better about the system",
          link: "/",
        },
      ],
    },
  ];
  const MenuLinks = [
    {
      name: "FusionPay",
      link: "/fusionpay",
    },
    {
      name: "Pricing",
      link: "/pricing",
    },
    {
      name: "Partner",
      link: "/partner",
    },
    {
      name: "Contact Us",
      link: "/contactus",
    },
  ];
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        className={`main-header`}
      >
        <Container>
          <div className="logo">
            {theme === "light" ? (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  <Image src={LogoLight} alt="" />
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to={"/"} eventKey={0}>
                  <Image src={LogoLight} alt="" />
                </Nav.Link>
              </>
            )}
          </div>
          <MenuBtn />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="">
              {DropMenuLinks.map((DropMenuLink, index) => (
                <NavDropdown
                  key={index}
                  title={DropMenuLink.title}
                  id="collasible-nav-dropdown"
                >
                  {DropMenuLink.submenus.map((submenu, index) => (
                    <NavDropdown.Item
                      key={index}
                      as={Link}
                      to={submenu.link}
                      eventKey={index}
                      className="drop-box"
                    >
                      <div class="menu-img-box">
                        <img loading="lazy" src={submenu.img} alt="" />
                      </div>
                      <div class="menu-txt-box">
                        <span class="text-uppercase">{submenu.name}</span>
                        <p>{submenu.dis}</p>
                      </div>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ))}

              {MenuLinks.map((MenuLink, index) => (
                <Nav.Link
                  key={index}
                  eventKey={index}
                  as={Link}
                  to={MenuLink.link}
                >
                  {MenuLink.name}
                </Nav.Link>
              ))}
            </Nav>
            <Nav>
              <div className="header-btn">
                <a href="tel:+442030263355" className="phone">
                  <img src={callicon} alt="" /> 0203 026 3355
                </a>
                {/* <Link to={"/"} className="demo-btn">
                Free Demo
              </Link> */}
                <DemoPopup />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;

function DemoPopup() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="demo-btn" onClick={handleShow}>
        Free Demo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
