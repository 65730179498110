import React from "react";
import "./ordersuccess.scss";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ordersuccess from "./img/Pizza-Ordered.gif";
import { Link } from "react-router-dom";

function Ordersuccess() {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };
  // const handleShow = () => setShow(true);
  return (
    <div>
      <section>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}

        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          centered
          className="ordersuccess"
        >
          <Modal.Header className="ordersuccesshead">
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="ordersuccessbody">
            <img
              src={ordersuccess}
              alt=""
              title=""
              className="ordersuccessimg"
            />
            <h4>Order Successfully</h4>
            <p>
              Your order will be packed by the clerk, will arrive at your house
              in 3-4 days
            </p>
          </Modal.Body>
          <Modal.Footer className="ordersuccessfoot">
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            <div className="trackingbtn">
              <Link to={"/ordertracking"}>
                <Button variant="primary" onClick={handleClose}>
                  Order Tracking
                </Button>
              </Link>
            </div>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
}

export default Ordersuccess;
