import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Data from "../pages/shopping/product.json";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import "./related.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import rect from "../pages/shopping/img/Category Rectangle.png";

export default function RelatedItems(props) {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <>
      <div className="item">
        <img src={rect} alt="" />
        <p>Related Item</p>
      </div>
      <Swiper
        spaceBetween={20}
        slidesPerView={5}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          421: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
        modules={[Autoplay]}
        className="related-items"
      >
        {Data.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className="box"
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <div className="offer">
                <button>-40%</button>
              </div>
              <img src={item.Image} alt="" />
              <div className="add">
                {hoveredItem === index && <button>Add To Cart</button>}
              </div>
              <div className="title">{item.ProductName}</div>
              <div className="price">
                {item.Price}
                <span>
                  <strike>£160</strike>
                </span>
              </div>
              <div className="rating">
                <>
                  {[...Array(item.Rating)].map((rating) => (
                    <div key={rating}>
                      <AiFillStar />
                    </div>
                  ))}
                  {[...Array(5 - item.Rating)].map((rating) => (
                    <div key={rating}>
                      <AiOutlineStar />
                    </div>
                  ))}{" "}
                </>
                <span className="rating-count">(30)</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
