import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BiDetail } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import truck from "../img/truck-tick.png";
import "../css/status.scss";
import { useCart } from "../product-details/context";
import { useState } from "react";
import { useEffect } from "react";
import RelatedItems from "../../../relateditems/related";
import StatusProgressbar from "../../../statusprogressbar/statusbar";
import { Link } from "react-router-dom";
import Ordersuccess from "../../ordersuccess/ordersuccess";

export default function Status() {
  const { cartData } = useCart(); // Destructure cartData here
  const [updatedCartData, setUpdatedCartData] = useState(cartData);

  const [showOrdersuccess, setShowOrdersuccess] = useState(true);

  // const handleset = () => {
  //   setShowOrdersuccess(true);
  // };

  const calculateSubtotal = () => {
    let subtotal = 0;
    for (const item of updatedCartData) {
      subtotal += item.quantity * item.price;
    }
    return subtotal;
  };
  const subtotal = calculateSubtotal();
  const shipping = 0;
  const vatPercentage = 0.2;

  const vat = subtotal * vatPercentage;
  const totalAmount = subtotal + shipping + vat;
  useEffect(() => {
    setUpdatedCartData(cartData);
  }, [cartData]);

  return (
    <section>
      <div className="order-status">
        <div className="details">
          <Container>
            <Row className="align-items-center">
              <Col xxl={8} xl={8} lg={8} md={6} sm={6} xs={7}>
                <div className="order-num">
                  <h4 className="title">Order ID: 3354654654526</h4>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={5}>
                <div className="buttons">
                  <div className="detail-btn">
                    <button>
                      <span className="detail-img">
                        <BiDetail />
                      </span>
                      Detail
                    </button>
                  </div>
                  <div className="track-btn">
                    <Link to={"/ordertracking"}>
                      <button>
                        Track order
                        <span className="track-icon">
                          <GrLocation />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h6 className="date">
                  Order date: <span className="color">Feb 16, 2023</span>
                  <span className="truck">
                    <img src={truck} alt="" />
                    <span className="green">
                      Estimated delivery: Mar 16, 2023
                    </span>
                  </span>
                </h6>
              </Col>
            </Row>
            <Row>
              <StatusProgressbar />
            </Row>
          </Container>
          <div className="product-bill-detail">
            <Container>
              <Row className="justify-content-between">
                <Col xxl={7} xl={8} lg={8} md={8} sm={7}>
                  {cartData.map((product, index) => (
                    <div className="product" key={index}>
                      <div className="product-img">
                        <img
                          src={product.selectedImage}
                          alt={product.productName}
                        />
                        <div className="title">{product.productName}</div>
                      </div>
                      <div className="price-qty">
                        <div className="price">£{product.price}</div>
                        <div className="qty">Qty: {product.quantity}</div>
                      </div>
                    </div>
                  ))}
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={5}>
                  <div className="bill">
                    <div className="order">Order Summary</div>
                    <div className="subtotal">
                      <div className="title">Subtotal</div>
                      <div className="price">£{subtotal.toFixed(2)}</div>
                    </div>
                    <div className="shipping">
                      <div className="title">Shipping</div>
                      <div className="price">£{shipping.toFixed(2)}</div>
                    </div>
                    <div className="vat">
                      <div className="title">VAT (20%)</div>
                      <div className="price">£{vat.toFixed(2)}</div>
                    </div>
                    <div className="total">
                      <div className="title">Total Amount</div>
                      <div className="price">£{totalAmount.toFixed(2)}</div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <RelatedItems />
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Ordersuccess
        showOrdersuccess={showOrdersuccess}
        setShowOrdersuccess={setShowOrdersuccess}
      />
    </section>
  );
}
