import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./newaddressupdate.scss";
// import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { CgCloseR } from "react-icons/cg";
// import { Link } from "react-router-dom";
import Addsuccess from "../addsuccess/addsuccess";

function Newaddressupdate({ setShowNewaddressupdate, showNewaddressupdate }) {
  const [emailValid, setEmailValid] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [gender, setGender] = useState("male");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const [showAddsuccess, setShowAddsuccess] = useState(false);

  const handleClose = () => {
    setShowNewaddressupdate(false);
    setValidated(false);
    setEmailValid(true);
  };

  const handleset = () => {
    localStorage.setItem("firstname", firstName);
    localStorage.setItem("lastname", lastName);
    localStorage.setItem("city", city);
    localStorage.setItem("postcode", postcode);
    localStorage.setItem("address1", address1);
    localStorage.setItem("address2", address2);
    localStorage.setItem("phone", phone);
    setShowAddsuccess(true);
    setShowNewaddressupdate(false);
  };
  // const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // if (form.checkValidity() === false || !emailValid) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    if (form.checkValidity() === false || !emailValid || !phoneValid) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  // const handlePhoneChange = (event) => {
  //   const newPhone = event.target.value;
  //   const numericPhone = newPhone.replace(/[^0-9]/g, "");
  //   setPhone(numericPhone);
  //   validatePhone(numericPhone);
  // };

  const validatePhone = (inputPhone) => {
    // Simple phone number validation using a regular expression
    const phoneRegex = /^\d{10}$/;
    const isValid = phoneRegex.test(inputPhone);
    setPhoneValid(isValid);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  return (
    <div>
      <section className="newaddressupdate">
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}

        <Modal
          show={showNewaddressupdate}
          onHide={handleClose}
          animation={false}
          centered
          className="newaddressupdate"
        >
          {/* <Modal.Header closeButton> */}
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          {/* </Modal.Header> */}
          <Modal.Body className="newaddressupdatebody">
            <Button
              variant="primary"
              onClick={handleClose}
              className="closebtn"
            >
              <CgCloseR />
            </Button>
            <br></br>
            <h4>Add New Address</h4>
            <br></br>
            <Container>
              <Form
                onSubmit={handleSubmit}
                validated={validated}
                className="newaddressupdateform"
              >
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom01"
                  >
                    <label className="newaddressupdatelabel">First Name:</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter your name.."
                      className="newaddressupdatename"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="invalid-feedback">
                      Enter a valid First name.
                    </div>
                    {/* <span style={{ color: "red" }}>{formErrors.firstName}</span> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="newaddressupdatelabel">Last Name:</label>

                    <input
                      className="newaddressupdatename"
                      required
                      type="text"
                      placeholder="Enter your name.."
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />

                    <div className="invalid-feedback">
                      Enter a valid Last Name.
                    </div>
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                </Row>
                <br></br>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="newaddressupdatelabel">
                      Address Line 1
                    </label>

                    <input
                      className="newaddressupdatename"
                      //   required
                      type="text"
                      placeholder="Enter Address Line 1"
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />

                    {/* <div className="invalid-feedback">
                      Enter a valid Last Name.
                    </div> */}
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="newaddressupdatelabel">
                      Address Line 2
                    </label>

                    <input
                      className="newaddressupdatename"
                      //   required
                      type="text"
                      placeholder="Enter Address Line 2"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />

                    {/* <div className="invalid-feedback">
                      Enter a valid Last Name.
                    </div> */}
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                </Row>
                <br></br>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom01"
                  >
                    <label className="newaddressupdatelabel">Town/City</label>

                    <input
                      required
                      type="text"
                      placeholder="Town/City"
                      className="newaddressupdatename"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />

                    {/* <div className="invalid-feedback">Enter Door No.</div> */}
                    {/* <span style={{ color: "red" }}>{formErrors.firstName}</span> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    xs="6"
                    controlId="validationCustom02"
                  >
                    <label className="newaddressupdatelabel">Post Code</label>

                    <input
                      className="newaddressupdatename"
                      required
                      type="text"
                      placeholder="Post Code"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                    />
                    {/* <div className="invalid-feedback">Post Code</div> */}
                    {/* <span style={{ color: "red" }}>{formErrors.lastName}</span> */}
                  </Form.Group>
                </Row>
                <br></br>
                <Row>
                  <Col xs={6} md={6}>
                    {" "}
                    <label htmlFor="phone" className="namelabel">
                      Mobile No:{" "}
                    </label>{" "}
                    <input
                      className={`newaddressupdatename ${
                        validated && !phoneValid ? "is-invalid" : ""
                      }`}
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="+44 - 098596 58000"
                      pattern="[0-9]{10}"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        validatePhone(e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">Must be 11 digits.</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="radioaddress">
                      <Form.Check
                        className="radiolabel"
                        type="radio"
                        label="Current Address"
                        name="gender"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleGenderChange}
                      />
                      <Form.Check
                        className="radiolabel"
                        type="radio"
                        label="Add Address"
                        name="gender"
                        value="female"
                        // checked={gender === "female"}
                        checked
                        onChange={handleGenderChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="submitbtn">
                      <Button variant="primary" onClick={handleset}>
                        Update Details
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
        <Addsuccess
          showAddsuccess={showAddsuccess}
          setShowAddsuccess={setShowAddsuccess}
        />
      </section>
    </div>
  );
}

export default Newaddressupdate;
