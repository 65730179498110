import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TbPlus } from "react-icons/tb";
import { CiSquarePlus } from "react-icons/ci";
import card from "../img/card.png";
import apple from "../img/applepay.png";
import klarna from "../img/klarna.png";
import "../css/billing.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import Newaddressupdate from "../../newaddressupdate/newaddressupdate";

export default function Billing({ cartData }) {
  const [updatedCartData, setUpdatedCartData] = useState(cartData);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showNewaddressupdate, setShowNewaddressupdate] = useState(false);

  const storedFirstName = localStorage.getItem("firstname") || "";
  const storedLastName = localStorage.getItem("lastname") || "";
  const storedCity = localStorage.getItem("city") || "";
  const storedPostcode = localStorage.getItem("postcode") || "";
  const storedAddress1 = localStorage.getItem("address1") || "";
  const storedAddress2 = localStorage.getItem("address2") || "";
  const storedPhone = localStorage.getItem("phone") || "";

  // const [addressValue, setAddressValue] = useState("");

  const handleset = () => {
    setShowNewaddressupdate(true);
  };

  const incrementQuantity = (itemId) => {
    const updatedData = updatedCartData.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    setUpdatedCartData(updatedData);
  };

  const decrementQuantity = (itemId) => {
    const updatedData = updatedCartData.map((item) => {
      if (item.id === itemId && item.quantity > 1) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }
      return item;
    });
    setUpdatedCartData(updatedData);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    for (const item of updatedCartData) {
      subtotal += item.quantity * item.price;
    }
    return subtotal;
  };

  const subtotal = calculateSubtotal();
  const shipping = 0;
  const vatPercentage = 0.2;

  const vat = subtotal * vatPercentage;
  const totalAmount = subtotal + shipping + vat;

  return (
    <section>
      <div className="billing-full">
        <Container>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={8} sm={8}>
              <div className="order-details">
                <div className="border-border">
                  <h1 className="titlle">Review Place Your Order</h1>
                  <h5 className="desc">Billing Address</h5>
                  <div className="add-button">
                    <button onClick={handleset} className="add-address">
                      ADD
                      <span className="plus">
                        <TbPlus />
                      </span>
                    </button>
                  </div>

                  <div className="address">
                    <div className="h5 address-tittle">Delivery to</div>
                    {storedFirstName && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="firstName">First Name:</label> */}
                        <input
                          type="text"
                          id="firstName"
                          value={storedFirstName}
                          readOnly
                        />
                      </div>
                    )}

                    {storedLastName && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="lastName">Last Name:</label> */}
                        <input
                          type="text"
                          id="lastName"
                          value={storedLastName}
                          readOnly
                        />
                      </div>
                    )}

                    {storedAddress1 && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="addressLine1">Address Line 1:</label> */}
                        <input
                          type="text"
                          id="addressLine1"
                          value={storedAddress1}
                          readOnly
                        />
                      </div>
                    )}

                    {storedAddress2 && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="addressLine2">Address Line 2:</label> */}
                        <input
                          type="text"
                          id="addressLine2"
                          value={storedAddress2}
                          readOnly
                        />
                      </div>
                    )}

                    {storedCity && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="city">City:</label> */}
                        <input
                          type="text"
                          id="city"
                          value={storedCity}
                          readOnly
                        />
                      </div>
                    )}

                    {storedPostcode && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="postcode">Postcode:</label> */}
                        <input
                          type="text"
                          id="postcode"
                          value={storedPostcode}
                          readOnly
                        />
                      </div>
                    )}

                    {storedPhone && (
                      <div className="input-fiel">
                        {/* Display data from local storage */}
                        {/* <label htmlFor="phone">Mobile Number:</label> */}
                        <input
                          type="text"
                          id="phone"
                          value={storedPhone}
                          readOnly
                        />
                      </div>
                    )}
                    <span className="edit">Edit</span>
                  </div>
                  <div className="notes">
                    <CiSquarePlus /> <span className="title">Leave a Note</span>
                  </div>
                </div>
                <div className="payment-modes">
                  <h5 className="headings">Payment Method</h5>
                  <div className="cards">
                    <button
                      className="normal"
                      onClick={() => setShowCardDetails(!showCardDetails)}
                    >
                      {showCardDetails ? null : <img src={card} alt="card" />}
                      <div className="selected">
                        {showCardDetails && <BsCheckCircleFill />}
                      </div>
                      <div className="title">Card</div>
                    </button>
                    <button className="normal">
                      <img src={apple} alt="apple" />
                      <div className="title">Apple Pay</div>
                    </button>
                    <button className="normal">
                      <img src={klarna} alt="klarna" />
                      <div className="title">Klarna</div>
                    </button>
                  </div>
                  {showCardDetails && (
                    <div className="credit-card">
                      <div className="title">
                        Enter Credit/Debit Card Details:
                      </div>
                      <input type="text" placeholder="Card Number" />
                    </div>
                  )}

                  <Link to="/status">
                    <button className="order-placed">Place your Order</button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
              <div className="details-details">
                <div className="bill-details">
                  <h5 className="title">Order Details</h5>
                </div>

                <div className="product">
                  {cartData.map((item) => (
                    <div key={item.id} className="items">
                      <div className="flex">
                        <img src={item.selectedImage} alt={item.productName} />
                        <div className="data">
                          <div className="title">{item.productName}</div>
                          <div className="price">£{item.price}</div>
                        </div>
                      </div>

                      <div className="quantity-controls">
                        <button onClick={() => incrementQuantity(item.id)}>
                          +
                        </button>
                        <div className="quantity">{item.quantity}</div>

                        <button onClick={() => decrementQuantity(item.id)}>
                          -
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="bill">
                  <div className="subtotal">
                    <div className="title">Subtotal</div>
                    <div className="price">£{subtotal.toFixed(2)}</div>
                  </div>
                  <div className="shipping">
                    <div className="title">Shipping</div>
                    <div className="price">£{shipping.toFixed(2)}</div>
                  </div>
                  <div className="vat">
                    <div className="title">VAT (20%)</div>
                    <div className="price">£{vat.toFixed(2)}</div>
                  </div>
                  <div className="total">
                    <div className="title">Total Amount</div>
                    <div className="price">£{totalAmount.toFixed(2)}</div>
                  </div>
                  <Link to="/product">
                    <button className="back-shopping">Back to Shopping</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showNewaddressupdate && (
        <Newaddressupdate
          showNewaddressupdate={showNewaddressupdate}
          setShowNewaddressupdate={setShowNewaddressupdate}
        />
      )}
    </section>
  );
}
