// *******~ Import ~******** //
// React
import React, { useState, useContext, createContext } from "react";
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
// Import Swiper React components
import Products from "./product.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
// import { Pagination } from "swiper/modules";
// Components

// CSS
import "./css/shopping.scss";
// Images
import SearchIcon from "./img/search.png";
import RightArrow from "./img/right-arrow.png";
import SliderImg from "./img/banner-img.png";
import DealOne from "./img/fusionpos-deal1.png";
import DealTwo from "./img/fusionpos-deal2.png";
// Icons
// SEO

// *******~ Import ~******** //

export const ContextDataProvider = createContext({});
export const ContextDataConsumer = () => useContext(ContextDataProvider);

export default function Home(second) {
  const [filter, setFilter] = useState("");

  const [filteredProducts, setFilteredProducts] = useState(Products);

  // Filter function
  const handleFilterChange = (e) => {
    const query = e.target.value;
    setFilter(query);

    // Filter the products based on the query
    const filtered =
      Products &&
      Products.filter((product) =>
        product.ProductName.toLowerCase().includes(query.toLowerCase())
      );
    setFilteredProducts(filtered);
  };
  return (
    <>
      <ContextDataProvider.Provider
        value={{
          filter,
          setFilter,
          handleFilterChange,
          filteredProducts,
          setFilteredProducts,
        }}
      >
        <section className="shopping-page">
          <Slider />
          <Deals />
          <Product />
        </section>
      </ContextDataProvider.Provider>
    </>
  );
}

const Slider = () => {
  const { filter, handleFilterChange } = ContextDataConsumer();
  return (
    <>
      <section className="shopping-slider">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="inside-box">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-6 col-md-6">
                    <h1>TOP DEALS</h1>
                    <p>POS hardware for any business.</p>
                    <form>
                      <div className="form-group">
                        <img src={SearchIcon} className="search-img" alt="" />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search for any product brand"
                          value={filter}
                          onChange={handleFilterChange}
                        />
                        <span>
                          <img src={RightArrow} className="arrow-img" alt="" />
                        </span>
                      </div>
                    </form>
                    {/* <span id="not-found-message" style={{ display: 'none' }}>Not Found</span> */}
                  </div>
                  <div className="col-xl-6 col-md-6">
                    <img
                      src={SliderImg}
                      alt="We Make Selling Easy"
                      title="We Make Selling Easy"
                      className="selling-easy-img"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function Deals() {
  const { filter } = ContextDataConsumer();
  return (
    <>
      {filter.length === 0 && (
        <>
          <section className="deal-slider">
            <Container>
              <Row>
                <Col xxl={12}>
                  <Swiper
                    spaceBetween={20}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    // modules={[Pagination]}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 2.5,
                        spaceBetween: 50,
                      },
                    }}
                    className="mySwiper"
                  >
                    {[...Array(5)].map((data, index) => (
                      <>
                        <SwiperSlide>
                          <Link to={"/"}>
                            <Image src={DealOne} fluid />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Link to={"/"}>
                            <Image src={DealTwo} fluid />
                          </Link>
                        </SwiperSlide>
                      </>
                    ))}
                  </Swiper>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  );
}

const Product = () => {
  const { filteredProducts } = ContextDataConsumer();

  const redirectToProductPage = (productName) => {
    const specialProducts = [
      "terminal",
      "printer",
      "card machine",
      "cash drawer",
      "stand",
      "android tab",
      "caller id",
      "kitchen display",
    ];

    const category = specialProducts.includes(productName.toLowerCase())
      ? productName
      : "Other";
    localStorage.setItem("ProductCategory", category);
  };

  return (
    <>
      <section className="products-section">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="heading">
                <h2>Explore by categories</h2>
                <Link
                  onClick={localStorage.setItem(
                    "ProductCategory",
                    "All Categories"
                  )}
                  to={"/product"}
                >
                  {"See All >"}
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="product-row">
            {filteredProducts.length === 0 ? (
              <p>
                {" "}
                <span className="not-found-message">No products found.</span>
              </p>
            ) : (
              <>
                {filteredProducts &&
                  filteredProducts.map((productlist, index) => (
                    <>
                      <Col xxl={3} md={6} sm={6} lg={4} xl={3} key={index}>
                        <Link
                          className="product-box"
                          to="/product"
                          onClick={() =>
                            redirectToProductPage(productlist.ProductName)
                          }
                        >
                          <h5>{productlist.ProductName}</h5>
                          <p>{productlist.Description}</p>
                          <img
                            src={productlist.Image}
                            alt="FusionPOS Terminal"
                            title="FusionPOS Terminal"
                          />
                          <button>from {productlist.Price}</button>
                        </Link>
                      </Col>
                    </>
                  ))}
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};
