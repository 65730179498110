import React from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";

import EmailImg from "./img/FusionPOS-Email.svg";
import PhoneImg from "./img/FusionPOS-Phone.svg";
import Facebook from "./img/social/FusionPOS-facebook.svg";
import Youtube from "./img/social/FusionPOS-youtube.svg";
import Instagram from "./img/social/FusionPOS-instagram.svg";
import Linkedin from "./img/social/FusionPOS-linkedin.svg";
import Twitter from "./img/social/FusionPOS-twitter.svg";
import TrustpilotRating from "./img/FusionPOS-Trustpilot-review.svg";
import AppleIcon from "./img/FusionPOS-apple-store.png";
import AndroidIcon from "./img/FusionPOS-android-app.png";
// import { FaPhoneSquareAlt } from "react-icons/fa";
const Footer = (params) => {
  const FooterLinks = [
    {
      LinkTitle: "ABOUT US",
      SubLinks: [
        {
          LinkName: "Our Organisation",
          url: "/",
        },
        {
          LinkName: "Partner",
          url: "/",
        },
        {
          LinkName: "Restaurant & Franchises",
          url: "/",
        },
        {
          LinkName: "Download Brochure",
          url: "/",
        },
        {
          LinkName: "Terms & Conditions",
          url: "/",
        },
        {
          LinkName: "Stuart Delivery T&C",
          url: "/",
        },
      ],
    },
    {
      LinkTitle: "PRODUCTS",
      SubLinks: [
        {
          LinkName: "Fusion Drive",
          url: "/",
        },
        {
          LinkName: "Chef Screen",
          url: "/",
        },
        {
          LinkName: "Fusion Partner",
          url: "/",
        },
        {
          LinkName: "Fusion Kitchen",
          url: "/",
        },
        {
          LinkName: "Contactless Dining",
          url: "/",
        },
      ],
    },
    {
      LinkTitle: "FEATURES",
      SubLinks: [
        {
          LinkName: "Mobile Apps",
          url: "/",
        },
        {
          LinkName: "Multi Printers",
          url: "/",
        },
        {
          LinkName: "Preorder",
          url: "/",
        },
        {
          LinkName: "Chinese Takeaway",
          url: "/",
        },
        {
          LinkName: "View All",
          url: "/",
        },
      ],
    },
    {
      LinkTitle: "EXPLORE",
      SubLinks: [
        {
          LinkName: "FAQ",
          url: "/",
        },
        {
          LinkName: "Blog",
          url: "/blog",
        },
        {
          LinkName: "Support",
          url: "/",
        },
        {
          LinkName: "Areas",
          url: "/",
        },
        {
          LinkName: "White Papers",
          url: "/",
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <footer>
        <Container>
          <Row>
            <Col xl={7} sm={12} lg={12}>
              <Accordion>
                <Row style={{ justifyContent: "space-between" }}>
                  {FooterLinks.map((FooterLink, index) => (
                    <Col key={index} xl={"auto"} md={"auto"}>
                      <Accordion.Item eventKey={index}>
                        <div className="menu-div">
                          <h4>{FooterLink.LinkTitle}</h4>
                          <Accordion.Header>
                            {FooterLink.LinkTitle}
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              {FooterLink.SubLinks.map((SubLink, index) => (
                                <li key={index}>
                                  <Link to={SubLink.url}>
                                    {SubLink.LinkName}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </Accordion.Body>
                        </div>
                      </Accordion.Item>
                    </Col>
                  ))}
                </Row>
              </Accordion>
            </Col>
            <FooterContact />
          </Row>
        </Container>
      </footer>
      <section className="copyright-sec">
        <Container>
          <Row>
            <Col xl={12}>
              <p>
                © 2023 | Fusion Innovative Limited T/A &nbsp;
                <Link to={"/"}>FusionPOS</Link>&nbsp; | &nbsp;
                <Link to={"privacy-policy"}>Privacy Policy - GDPR</Link>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Footer;

const FooterContact = (params) => {
  const MailTelLinks = [
    {
      name: "sales@fusionpos.co.uk",
      link: "mailto:sales@fusionpos.co.uk",
      img: EmailImg,
      target: "_blank",
    },
    {
      name: "0203 026 3355",
      link: "tel:+442030263355",
      img: PhoneImg,
      target: "_self",
    },
  ];
  const SocialMedias = [
    {
      name: "Facebook",
      link: "https://www.facebook.com/FusionPOS/",
      img: Facebook,
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/@fusionpos9803",
      img: Youtube,
    },
    {
      name: "Linkedin",
      link: "https://www.linkedin.com/company/fusionposuk",
      img: Linkedin,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/accounts/login/?next=/fusion.pos/",
      img: Instagram,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/Fusion_POS",
      img: Twitter,
    },
  ];
  return (
    <React.Fragment>
      <Col xl={5} sm={12}>
        <Row>
          <Col xl={7} lg={4} sm={6}>
            <div className="contact-div">
              <h4>CONNECT WITH US</h4>
              <div className="call-mail">
                {MailTelLinks.map((MailTelLink, index) => (
                  <a
                    key={index}
                    href={MailTelLink.link}
                    target={MailTelLink.target}
                    rel="noopener noreferrer"
                  >
                    <img src={MailTelLink.img} alt="" />
                    {MailTelLink.name}
                  </a>
                ))}
              </div>
              <div className="social-media">
                <ul>
                  {SocialMedias.map((SocialMedia, index) => (
                    <li key={index}>
                      <a
                        href={SocialMedia.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={SocialMedia.img} alt="" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <ul className="apps">
                <li>
                  <a
                    href="https://apps.apple.com/in/app/fusion-partner/id1502807167"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={AppleIcon} alt="" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={AndroidIcon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={5} lg={3} sm={4}>
            <div className="trustpilot">
              <a
                href="https://www.trustpilot.com/review/fusionpos.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TrustpilotRating}
                  alt="FusionPOS Trustpilot Review"
                  title="FusionPOS Trustpilot Review"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};
