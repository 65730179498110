import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./router/router";
import Header from "./common/header/header";
import Footer from "./common/footer/footer";
import WhatsAppChat from "./common/whatsapp-chat/whatsapp-chat";

import GoTop from "./common/gototop/gototop";
import ScrollToTop from "./common/scrolltop/scrolltop";
export default function Main(params) {
  return (
    <>
      <Router basename={"/"}>
        <GoTop />
        <ScrollToTop />
        <WhatsAppChat />
        <Header />
        <main>
          <Routing />
        </main>
        <Footer />
      </Router>
    </>
  );
}
