import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "../../shopping/css/cart.scss";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import icon from "../img/🦆 icon _shopping bag_.png";
import RelatedItems from "../../../relateditems/related";
import StepProgressBar from "../../../progressbar/progress";
import Billing from "./billing";

export default function CartDetail({ cartData, clearCartData }) {
  const [itemQuantities, setItemQuantities] = useState({});
  const [progressstatus, Setprogressstatus] = useState(false);

  useEffect(() => {
    setItemQuantities(
      cartData.reduce((quantities, item) => {
        quantities[item.id] = item.quantity;
        return quantities;
      }, {})
    );
  }, [cartData]);

  const incrementQuantity = (itemId) => {
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: prevQuantities[itemId] + 1,
    }));
  };

  const decrementQuantity = (itemId) => {
    setItemQuantities((prevQuantities) => {
      const updatedQuantity = prevQuantities[itemId] - 1;

      const newQuantity = Math.max(updatedQuantity, 0);
      return {
        ...prevQuantities,
        [itemId]: newQuantity,
      };
    });
  };

  useEffect(() => {
    cartData.map((item) => ({
      ...item,
      quantity: itemQuantities[item.id],
    }));
  }, [itemQuantities, cartData]);

  const calculateSubtotal = () => {
    let subtotal = 0;
    if (Array.isArray(cartData) && cartData.length > 0) {
      cartData.forEach((item) => {
        subtotal += item.price * itemQuantities[item.id];
      });
    }
    return subtotal;
  };

  const calculateVAT = () => {
    const subtotal = calculateSubtotal();
    const vatRate = 0.2;
    const vat = subtotal * vatRate;
    return Math.round(vat); // Round VAT to the nearest whole number
  };

  const calculateTotal = () => {
    if (Array.isArray(cartData) && cartData.length === 0) {
      return 0;
    }
    const subtotal = calculateSubtotal();
    const vat = calculateVAT();
    const shippingCost = 0;
    return subtotal + vat + shippingCost;
  };

  const clearCart = () => {
    clearCartData();
  };

  const handleprogress = () => {
    Setprogressstatus(true);
  };

  return (
    <section>
      <StepProgressBar progressstatus={progressstatus} />

      {progressstatus ? (
        <div className="billing-section" id="display-2">
          <Billing cartData={cartData} />
        </div>
      ) : (
        <div className="cart-full" id="display-1">
          <Container>
            <Row>
              <Col xxl={8} xl={8} lg={8} md={8} sm={12}>
                <div className="cart-table">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="product">Product</th>
                        <th className="product">Price</th>
                        <th className="product">Quantity</th>
                        <th className="product">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(cartData) && cartData.length > 0 ? (
                        cartData.map((item) => (
                          <tr key={item.id} className="items-items">
                            <td>
                              <div className="items">
                                <img
                                  src={item.selectedImage}
                                  alt={item.productName}
                                />
                                <div className="title">{item.productName}</div>
                              </div>
                            </td>
                            <td className="price-td">
                              <div className="price">£{item.price}</div>
                            </td>
                            <td className="special-quantity">
                              <div className="Quantity">
                                <button
                                  onClick={() => decrementQuantity(item.id)}
                                >
                                  -
                                </button>
                                <div className="number">
                                  {itemQuantities[item.id]}
                                </div>

                                <button
                                  onClick={() => incrementQuantity(item.id)}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="subtotal">
                                £{item.price * itemQuantities[item.id]}
                                <div className="close">
                                  <AiOutlineCloseCircle />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">Your cart is empty.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="functions-buttons">
                    <div className="button-continue">
                      <Link to="/product">
                        <Button>Continue shopping</Button>
                      </Link>
                    </div>
                    <div className="clear">
                      <Button onClick={clearCart}>Clear Cart</Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                <div className="cart-details">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="cart-title">Cart total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="calculation">
                            <div className="totlal ">
                              <div className="title">Subtotal</div>
                              <div className="amount">
                                £{calculateSubtotal()}
                              </div>
                            </div>
                            <div className="totlal ">
                              <div className="title">Shipping</div>
                              <div className="amount">£0</div>
                            </div>
                            <div className="totlal ">
                              <div className="title">VAT (20%)</div>
                              <div className="amount">£{calculateVAT()}</div>
                            </div>
                            <div className="totlal-total ">
                              <div className="title">Total amount</div>
                              <div className="amount">£{calculateTotal()}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="check-button">
                            <button onClick={() => handleprogress()}>
                              Checkout
                              <span>
                                <img src={icon} alt="" />
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <div className="related-items">
                  <RelatedItems />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </section>
  );
}
