// import React, { useEffect } from "react";
import CartDetail from "../cart/cart";
import { useCart } from "../../shopping/product-details/context";

export default function Stepone() {
  const { cartData, clearCart } = useCart();

  return (
    <div>
      <CartDetail cartData={cartData} clearCartData={clearCart} />
    </div>
  );
}
