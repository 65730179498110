import { Routes, Route } from "react-router-dom";
import Shopping from "../pages/shopping/shopping";
import NotFound from "../pages/error/404";
import ProductList from "../pages/shopping/product-list";
import ProductDetails from "../pages/shopping/product-details/product-details";
import Stepone from "../pages/shopping/payments/stepone";
import Status from "../pages/shopping/cart/status";
import Shopsigninpopup from "../pages/shopsigninpopup/shopsigninpopup";
import Detailsupdate from "../pages/detailsupdate/detailsupdate";
import Newaddressupdate from "../pages/newaddressupdate/newaddressupdate";
import Addsuccess from "../pages/addsuccess/addsuccess";
import Ordersuccess from "../pages/ordersuccess/ordersuccess";
import Ordertracking from "../pages/ordertracking/ordertracking";

const Routing = () => {
  return (
    <Routes>
      <Route exact path={"/"} element={<Shopping />} />
      <Route exact path={"*"} element={<NotFound />} />
      <Route exact path={"/product"} element={<ProductList />} />
      <Route path="/product/:Id" element={<ProductDetails />} />
      <Route path="/cart" element={<Stepone />} />
      <Route path="/status" element={<Status />} />
      <Route path="/shopsigninpopup" element={<Shopsigninpopup />} />
      <Route exact path={"/detailsupdate"} element={<Detailsupdate />} />
      <Route exact path={"/addsuccess"} element={<Addsuccess />} />
      <Route exact path={"/ordersuccess"} element={<Ordersuccess />} />
      <Route exact path={"/newaddressupdate"} element={<Newaddressupdate />} />
      <Route exact path={"/ordertracking"} element={<Ordertracking />} />
    </Routes>
  );
};
export default Routing;
