import React, { useEffect } from "react";
import { BsFillCartCheckFill } from "react-icons/bs";
import { useCart } from "./context";

function Cart() {
  const { cartData, setCartData } = useCart();

  const totalQuantity = cartData.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const totalPrice = cartData.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Synchronize with local storage when the component mounts
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));

    if (Array.isArray(storedCart) && storedCart.length > 0) {
      setCartData(storedCart);
    }
  }, [setCartData]);

  return (
    <div className="cart">
      <button>
        <BsFillCartCheckFill />
        <p>
          <span>Items: {totalQuantity} </span>
          <span>£{totalPrice}</span>
        </p>
      </button>
    </div>
  );
}

export default Cart;
