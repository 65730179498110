import React, { Component } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Container, Row, Col } from "react-bootstrap";
import "./progress.scss";
import { Link } from "react-router-dom";

class StepProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0, // Initialize the progress to 0%
    };
  }

  handleCheckoutClick = () => {
    // Update the progress to 50% when the "Checkout" button is clicked
    this.setState({ progress: 50 });
  };
  componentDidUpdate(prevProps) {
    if (this.props?.progressstatus && !prevProps.progressstatus) {
      // Update the progress to 50% when progressstatus changes to true
      this.setState({ progress: 50 });
    }
  }

  render() {
    return (
      <div className="progress-action">
        <Container>
          <Row className="align-items-center">
            <Col xxl={2} xl={2} lg={2} md={3} sm={3} xs={4}>
              <div className="back-functionality">
                <div className="back-button">
                  <Link to="/product">
                    <button>
                      <MdKeyboardArrowLeft /> Go back
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xxl={9} xl={9} lg={9} md={8} sm={8} xs={7}>
              <ProgressBar
                percent={this.state.progress}
                filledBackground="linear-gradient(to right, #3F80EE)"
                disabled={this.props?.progressstatus === true ? false : true}
              >
                <Step transition="scale">
                  {({ accomplished }) => (
                    <div className="view">
                      <span className="span-number">1</span>

                      {/* <div className="name">cart</div> */}
                    </div>
                  )}
                </Step>
                <Step transition="scale">
                  {({ accomplished }) => (
                    <div className="view">
                      <span className="span-number">2</span>
                    </div>
                  )}
                </Step>
                <Step transition="scale">
                  {({ accomplished }) => (
                    <div className="view">
                      <span className="span-number">3</span>
                    </div>
                  )}
                </Step>
              </ProgressBar>
              <div className="hidden-button">
                <button onClick={this.handleCheckoutClick}>Checkout</button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default StepProgressBar;
