// *******~ Import ~******** //
// React
// Assets
// Components
import Main from "./main";
// import ThemeSetter from "./common/theme/components/themeshetter";
import ThemeProvider from "./common/theme/components/contexts/themeprovider";
import { CartProvider } from "./pages/shopping/product-details/context";

// CSS
// Images
// Icons

// Google Analytics
// import { useEffect } from "react";
// import { initGA } from "./google-analytics";
// Google Analytical
// *******~ Import ~******** //
function App() {
  return (
    <>
      <ThemeProvider>
        {/* <ThemeSetter /> */}
        <CartProvider>
          <Main />
        </CartProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
